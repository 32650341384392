import React from 'react';
import './topbar.scss';
import logoSF from './logoSFLabs.png';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby'
// import FlyDigitalLogo from '../flyDLogo/flyD.logo'

class TopBar extends React.Component {
  scrollFlag = true
  flag = true
  test = "logoStyle-none"
  tel = ""
  itensContent = [
    ["home", "/#home"],
    ["sobre", "/#sobre"],
    ["produtos", "/#produtos"],
    ["carreira", "http://www.carreira.sflabs.com.br"],
    ["contato", "/#contato"]
  ]
  constructor(props) {
    super(props);
    this.state = { whatsappLink: `https://web.whatsapp.com//send?phone=${this.props.telLinkWpp}` }


    // this.scrollFlag = true;
    this.state = {
      topBarPosition: { fontSize: '1.3em' },
    }

  }
  componentDidMount() {
    if (window.scrollY > 10) {
      this.setState({
        topBarColor: { backgroundColor: '#1a1a1a' }
      })
    }
    window.document.addEventListener('scroll', () => {
      if (this.flag == true) {
        this.setState({
          topBarColor: { backgroundColor: '#1a1a1a' }
        })
        this.flag = false
      }
      if (window.scrollY > 10 && !this.scrollFlag) {
        this.scrollFlag = true
        this.setState({
          topBarColor: { backgroundColor: '#1a1a1a' }
        })
      }

      if (window.scrollY < 10 && this.scrollFlag) {
        this.scrollFlag = false
        this.setState({
          topBarColor: { backgroundColor: 'transparent' }
        })
      }
    })
  }

  generateItens(itensContent) {
    return itensContent
      .map(item =>
        <p className="pStyle" key={item}>
          <a className="pStyle1" href={item[1]} style={{ color: 'white', textDecoration: 'none' }}>  {item[0]}</a>
        </p>
      )
  }

  toggleOnSideBar() {
    this.props.toggleOn()
  }

  toggleOffSideBar() {
    this.props.toggleOff()
  }

  checkForMobile() {
    if (navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    else {
      return false;
    }
  }

  render() {

    return (

      <nav>

        <div className="Topbar">
          <div className="barStyle" style={this.state.topBarColor}>
            <div className="logoTopBarDesktop" >
              <Link to={"/"}><img src={logoSF} className="logoTopBar"></img></Link>
            </div>

            <div className={"logoStyle"}  >
              {this.props.flag ?
                <i className="material-icons fullscreen" style={{ color: 'white', fontSize: '2.2em', padding: '10px 10px', zIndex: '200' }} onClick={this.props.flag ? this.toggleOnSideBar.bind(this) : this.toggleOffSideBar.bind(this)} >menu</i> :
                <i className="material-icons fullscreen" style={{ color: 'white', fontSize: '2.2em', padding: '10px 10px', zIndex: '200' }} onClick={this.props.flag ? this.toggleOnSideBar.bind(this) : this.toggleOffSideBar.bind(this)}  >close</i>
              }
              {this.props.flag &&


                <div className="logoTopBarMobile" >
                  <img src={logoSF} className="logoTopBar"></img>
                </div>


              }

            </div>
            <div className="itemStyle" >
              {this.generateItens(this.itensContent)}
            </div>
          </div>



        </div>
      </nav>

    )
  }
}

export default TopBar